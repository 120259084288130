import React, { useEffect } from 'react'

import styles from './adventure-success.module.less'
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
import { Page } from "@components/common/page";
import { ReactComponent as PrizeIcon } from "@packages/components/icons/prize.svg";
import { PageHeader, PageSubheader, Title } from "@packages/components/typography";
import { withAdventure, WithAdventureProps } from "@components/common/with-adventures";
import { Boxy } from "@packages/components/layout/boxy";
import { useAdventureStatus, useResetAdventureStatus } from "@hooks/useStatus";
import { InfoBox } from "@packages/components/layout/info-box";

import { ReactComponent as Grail } from "@packages/components/graphics/grail.svg";
import { SuccessButton } from "@packages/components/common/success-button";
import { BackButton } from "@packages/components/common/back-button";
import { ReactComponent as Star } from "@packages/components/graphics/star.svg";
import { Animated } from "react-animated-css";
import useSound from "use-sound";
import {play, SOUND} from "@packages/commons/src/sound";

type AdventureSuccessProps = {} & WithAdventureProps

const AdventureSuccess = withAdventure(({ adventure }: AdventureSuccessProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { orgId } = useParams()
    const status = useAdventureStatus()
    const resetStatus = useResetAdventureStatus()

    const Footer = (<div>
        <BackButton block onClick={() => navigate(`/${orgId}`)} >{t('adventure_success.back')}</BackButton>
        {
            adventure.showPrize && status.prize &&
            <SuccessButton block onClick={() => {
                play(SOUND.TAP)
                resetStatus()
                navigate(`/${orgId}`)
            }} >{t('adventure_success.prize_claimed')}</SuccessButton>
        }
    </div>)

    return (
        <Page mode="gradient" className={styles.root} footer={Footer}>
            <Animated className={styles.mainIcon} animationInDelay={200} animationIn="bounceIn" animationOut="fadeOut" isVisible={true}>
                <Grail />
            </Animated>
            <div className={styles.main}>
                <PageHeader size="large">
                    {t('adventure_success.header')}
                </PageHeader>
                <PageSubheader>
                    {t('adventure_success.description')}
                </PageSubheader>
            </div>
            {
                adventure.showPrize && status.prize &&
                <>
                    <PageSubheader >
                        <p dangerouslySetInnerHTML={{ __html: adventure.prizeInfo! || t('adventure_success.prize_subheader') }} />
                    </PageSubheader>
                    <Boxy className={styles.code}>
                        <span>{status?.prize?.code}</span>
                    </Boxy>
                    <InfoBox mode="inverted" className={styles.prizeAdditionalInfo}>
                        {t('adventure_success.info')}
                    </InfoBox>
                </>
            }
        </Page>
    )
})

export { AdventureSuccess }