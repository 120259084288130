import React from "react";
import {Adventure} from "@packages/types/domain";
import {useAdventures} from "@hooks/useFirestore";
import {Spinner} from "@packages/components/common/spinner";
import {useParams} from "react-router-dom";

export type WithAdventuresProps = {
    adventures: Adventure[]
}

export const withAdventures = <T extends WithAdventuresProps>(Component: React.ComponentType<T>, showLoader = true) => (props: Omit<T, keyof WithAdventuresProps>) => {
    const adventures = useAdventures()

    if (adventures.isLoading) {
        if (showLoader) {
            return <Spinner centered />
        } else {
            return null;
        }
    }
    if (!adventures.data) {
        return <div>No data</div>
    }

    return <Component {...(props as T)} adventures={adventures.data!}  />
}

export type WithAdventureProps = {
    adventure: Adventure
}

export const withAdventure = <T extends WithAdventureProps & WithAdventuresProps >(Component: React.ComponentType<T>, showLoader = true) => withAdventures((props: Omit<T, keyof WithAdventureProps>) => {
    const {adventureId} = useParams()
    const adventure =  props.adventures.find(a => a.id === adventureId)
    return <Component {...(props as T)} adventure={adventure!}  />
}, showLoader)