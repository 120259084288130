import React from 'react'

import styles from './difficulty.module.less'
import {Difficulty} from "@packages/types/domain";
import {ReactComponent as Sun} from "@packages/components/icons/sun.svg";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

type DifficultyComponentProps = {
    difficulty: Difficulty
}

const DIFFICULTY_MAPPING: { [key in Difficulty]: boolean[] } = {
    'easy': [true, false, false],
    'normal': [true, true, false],
    'hard': [true, true, true]
}

const DifficultyComponent = ({difficulty}: DifficultyComponentProps) => {
    const {t} = useTranslation("common")
    return (
        <div className={clsx(styles.root, difficulty)}>
            {t(`components.difficulty.${difficulty}`)}
        </div>
    )
}

export {DifficultyComponent}