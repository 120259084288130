import { Button } from 'antd'
import React from 'react'

import styles from './toggle-button.module.less'
import {ButtonProps} from "antd/lib/button/button";
import clsx from "clsx";

import { motion } from "framer-motion"

type SuccessButtonProps = Omit<ButtonProps, "type"> & {checked: boolean}

const ToggleButton = ({className, checked, block, ...rest}: SuccessButtonProps) => {
    return (
        <motion.div whileTap={{ scale: 0.8 }} className={clsx(styles.container,styles.block && styles.block)}>
            <Button block={block} type="text" {...rest} className={clsx(styles.root, className, checked && styles.checked)} />
        </motion.div>
    )
}

export {ToggleButton}