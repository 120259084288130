import { RefObject, useCallback, useEffect, useState } from 'react'
import { useEventListener } from 'usehooks-ts'

interface Size {
    width: number
    height: number
}

// we use useEffect instead of useLayoutEffect as we want it to be client side only
function useElementSize<T extends HTMLElement = HTMLDivElement>(elementRef: RefObject<T> | null): Size {
    const [size, setSize] = useState<Size>({
        width: 0,
        height: 0,
    })

    // Prevent too many rendering using useCallback
    const updateSize = useCallback(() => {
        const node = elementRef?.current
        if (node) {
            setSize({
                width: node.offsetWidth || 0,
                height: node.offsetHeight || 0,
            })
        }
    }, [elementRef])

    // Initial size on mount
    useEffect(() => {
        updateSize()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEventListener('resize', updateSize)

    return size
}

export default useElementSize
