import React, {useCallback, useEffect} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {NotFound} from "@packages/components/status/not-found";
import {Adventure} from "@components/screens/adventure";
import {Riddle} from "@components/screens/riddle";
import {NoOrganization} from "@components/screens/no-organization";
import {Adventures} from "@components/screens/adventures";
import {FindByCode} from "@components/screens/find-by-code";
import {Layout} from "@components/common/layout";
import {RiddleSuccess} from "@components/screens/riddle-success";
import {AdventureSuccess} from "@components/screens/adventure-success";
import styles from './app.module.less'
import {useLocation} from "@packages/components/hooks/useLocation";
import {trackLocation} from "@packages/commons/src/geo/track";
import {notification} from "antd";

export const App = () => {
    return (
        <Routes>
            <Route index element={<NoOrganization />} />
            <Route path="/" element={<Layout />} >
                <Route path=":orgId/adventure/:adventureId/riddle/:riddleId/success" element={<RiddleSuccess />} />
                <Route path=":orgId/adventure/:adventureId/riddle/:riddleId" element={<Riddle />} />
                <Route path=":orgId/adventure/:adventureId/success" element={<AdventureSuccess />}  />
                <Route path=":orgId/adventure/:adventureId" element={<Adventure />}  />
                <Route path="/:orgId/code/:code" element={<FindByCode />} />
                <Route path=":orgId" element={<Adventures />}  />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
};
