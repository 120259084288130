import React from 'react'

import styles from './page.module.less'
import {useTranslation} from "react-i18next";
import {PageHeader, PageSubheader, TypoPropsWithSize} from "@packages/components/typography";
import {Link} from "react-router-dom";
import {Box} from "@packages/components/layout/box";
import {AdventureComponent} from "@packages/components/adventure";
import clsx from "clsx";
import {ReactComponent as Trees} from "@packages/components/graphics/trees.svg";
import {motion, AnimatePresence} from "framer-motion"
import {useLocation} from "react-router";

type PageMode = 'gradient' | 'default' | 'inverted'

type PageProps = {
    header?: React.ReactNode
    subHeader?: React.ReactNode
    children?: React.ReactNode
    footer?: React.ReactNode
    className?: string
    mode: PageMode
    headerSize?: TypoPropsWithSize["size"],
}

/*initial={{ scale: 0 }}

}}*/

const Page = ({header, headerSize = "normal", subHeader, children, footer, className, mode}: PageProps) => {
    return (
        <AnimatePresence>
            <motion.div
                key={location.pathname}
                initial={{x: 0}}
                transition={{
                    type: "tween",
                }}
                className={clsx(styles.root, styles[mode], className)}>
                {
                    !footer &&
                    <Trees className={styles.trees}/>
                }
                {
                    header &&
                    <header>
                        <PageHeader size={headerSize}>
                            {header}
                        </PageHeader>
                        {subHeader &&
                            <PageSubheader>
                                {subHeader}
                            </PageSubheader>
                        }
                    </header>
                }
                {
                    children &&
                    <main>
                        {children}
                    </main>
                }
                {
                    footer &&
                    <footer>
                        {footer}
                    </footer>
                }
            </motion.div>
        </AnimatePresence>
    )
}

export {Page}