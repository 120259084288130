import React, {useState} from 'react'
import {useTranslation} from "react-i18next";
import {Box} from "@packages/components/layout/box";
import {withAdventures, WithAdventuresProps} from "@components/common/with-adventures";
import {AdventureComponent} from "@packages/components/adventure";
import {ReactComponent as Reward} from "@packages/components/icons/reward.svg";
import {ReactComponent as RightArrow} from "@packages/components/icons/right-arrow.svg";
import {useNavigate} from "react-router-dom";
import {Page} from "@components/common/page";
import {useResetAdventureStatus, useStatus} from "@hooks/useStatus";

import styles from './adventures.module.less'
import {getAllRiddlesLength,} from "@packages/commons/src/domain";
import {Adventure} from "@packages/types/domain";
import {Modal, Result} from "antd";
import {Centered} from "@packages/components/layout/centered";
import {MehOutlined} from "@ant-design/icons";
import Countdown from "react-countdown";
import {ReactComponent as Grail} from "@packages/components/graphics/grail.svg";
import {PageHeader} from "@packages/components/typography";
import {BackButton} from "@packages/components/common/back-button";
import {SuccessButton} from "@packages/components/common/success-button";

import {motion} from "framer-motion"
import {play, SOUND} from "@packages/commons/src/sound";
import {InfoBox} from "@packages/components/layout/info-box";

type AdventuresProps = {} & WithAdventuresProps

const addZero = (num: number) => `${num < 10 ? '0' : ""}${num}`
const isActualPrize = (date: Date | undefined) => date && (new Date().getTime() < date.getTime())

const Adventures = withAdventures(({adventures}: AdventuresProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [status] = useStatus()
    const resetStatus = useResetAdventureStatus()
    const [showWarnModalId, setShowWarnModalId] = useState<string | null>(null)

    const onAdventureClickCallback = (adventure: Adventure) => {
        const adventuresStatus = status[adventure.id]

        if (adventuresStatus?.prize) {
            setShowWarnModalId(adventure.id)
        } else {
            navigate(`adventure/${adventure.id}`)
        }
    }

    const resetAdventureState = (id: string) => {
        resetStatus(id)
        navigate(`adventure/${id}`)
    }

    if (!adventures.length) {
        return (
            <Page mode="gradient" className={styles.main} header={t('adventures.header')} subHeader={t('adventures.empty')} />
        )
    }

    return (
        <>
            <Page mode="gradient" className={styles.main} header={t('adventures.header')}
                  subHeader={t('adventures.subheader')}>
                {
                    adventures.filter(a => getAllRiddlesLength(a) > 0).map((adventure) => {
                        const adventureStatus = status[adventure.id]
                        return (
                            <motion.div key={adventure.id} whileTap={{scale: 0.9}} >
                                <Box onClick={() => {
                                    play(SOUND.TAP)
                                    onAdventureClickCallback(adventure)
                                }}>
                                    <AdventureComponent status={adventureStatus} mode="app" adventure={adventure}/>
                                    {
                                        adventureStatus?.prize && isActualPrize(adventureStatus?.prize.expiresAt) &&
                                        <div className={styles.prizeInfo}
                                             onClick={() => {
                                                 play(SOUND.TAP)
                                                 navigate(`adventure/${adventure.id}/success`)
                                             }
                                             }>
                                            <div>
                                                <Grail width={24} height={24}/>
                                                {t('adventures.get_prize')}
                                            </div>
                                            <div>
                                                <Countdown renderer={({minutes, seconds, completed}) =>
                                                    <span>{completed ? '-' : ''}{addZero(minutes)}:{addZero(seconds)}</span>}
                                                           overtime={true}
                                                           date={new Date(adventureStatus.prize.expiresAt)}/>,
                                            </div>
                                        </div>
                                    }
                                </Box>
                            </motion.div>
                        )
                    })
                }
            </Page>
            <Modal footer={null}
                   title={null}
                   closable={false}
                   visible={!!showWarnModalId}>
                <div className={styles.modal}>
                    <PageHeader>{t('adventures.reset_title')}</PageHeader>
                    <p>
                        {t('adventures.reset_progress_warning')}
                    </p>
                    {
                        showWarnModalId && isActualPrize(status[showWarnModalId]?.prize?.expiresAt) &&
                        <div>{t('adventures.reset_progress_prize_suffix')}</div>
                    }
                    <footer className={styles.modalFooter}>
                        <BackButton block onClick={() => {
                            setShowWarnModalId(null)
                            play(SOUND.TAP)
                        }}>{t('cancel', {ns: "common"})}</BackButton>
                        <SuccessButton block
                            onClick={() => {
                                resetAdventureState(showWarnModalId!)
                                play(SOUND.TAP)
                            }}>{t('ok', {ns: "common"})}</SuccessButton>
                    </footer>
                </div>
            </Modal>
        </>
    )
})

export {Adventures}