import React from 'react'
import {useTranslation} from "react-i18next";
import clsx from 'clsx'
import {Progress, Tooltip} from "antd";

import {Title, Label, Number} from "@packages/components/typography";
import {Adventure, AdventureStatus, Mode} from "@packages/types/domain";
import {ReactComponent as Hero} from "@packages/components/icons/hero.svg";
import {ReactComponent as Block} from "@packages/components/icons/block.svg";
import {ReactComponent as Grail} from "@packages/components/graphics/grail.svg";

import { DifficultyComponent } from './difficulty';

import styles from "./adventure.module.less"
import {getActiveRiddlesLength, getAllRiddlesLength, getFinishedRiddles} from "@packages/commons/src/domain";

type AdventureComponentProps = {
    adventure: Adventure
    status?: AdventureStatus
    className?: string
    mode?: Mode
}

const AdventureComponent = ({adventure, status, className, mode = 'app'}: AdventureComponentProps) => {
    const {t} = useTranslation("common")
    const activeRiddlesLength = getActiveRiddlesLength(adventure)
    const allRiddlesLength = getAllRiddlesLength(adventure)
    const percentage = Math.round((getFinishedRiddles(adventure, status?.riddles)/allRiddlesLength) * 100)

    return (
        <div className={clsx(styles.root, className, mode)}>
            <header>
                <Title>{adventure.name}</Title>
                {
                    mode === 'app' && (status?.finishedCount || 0) > 0 &&
                    <div className={styles.rewardInfo}>
                        <Number>{status?.finishedCount}</Number>
                        <Grail width={24} height={24} />
                    </div>
                }
            </header>
            <main>
                <section>
                    <Label size="large">{t('difficulty')}</Label>
                    <DifficultyComponent difficulty={adventure.difficulty!}  />
                </section>
                <section>
                    <Label size="large">{t('age')}</Label>
                    <div>
                        <Hero className={styles.iconWithMargin} />
                        <Number>{adventure.age}+</Number>
                    </div>
                </section>
                <section>
                    <Label size="large">{t('components.adventure.riddles_length')}</Label>
                    <div>
                        <Block className={styles.iconWithMargin} />
                        {
                            mode === 'app' || allRiddlesLength === 0
                            ? <Number>{activeRiddlesLength}</Number>
                            : <>
                                <Tooltip className={styles.tooltip} title={t("components.adventure.active", {ns: "common"})} >
                                    <div>
                                        <Number>{activeRiddlesLength}</Number>
                                    </div>
                                </Tooltip>
                                    /&nbsp;<strong>{allRiddlesLength}</strong>
                                </>
                        }
                    </div>
                </section>
            </main>
            {
                mode === 'app' && allRiddlesLength &&
                <footer>
                    <Progress trailColor={"#DCEBEA"} showInfo={false} percent={percentage} />
                    <Number  size="large">
                        {percentage} %
                    </Number>
                </footer>
            }
            {
                mode === 'app' && adventure.description &&
                <div className={styles.description}>{adventure.description}</div>
            }
        </div>
    )
}

export {AdventureComponent}