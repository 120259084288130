import {FirebaseApp, initializeApp} from 'firebase/app';
import {setDoc, updateDoc, doc, Firestore, getDoc, getFirestore, collection, getDocs, deleteDoc} from "firebase/firestore";
import { enableMultiTabIndexedDbPersistence } from "firebase/firestore";

const config = {
    apiKey: "AIzaSyC6y9f89ECj5dZi79Ptoi2z7qQwlRbTPm8",
    authDomain: "georiddle-af49b.firebaseapp.com",
    projectId: "georiddle-af49b",
    appId: "1:944140708907:web:0adfda94bf420fbac94e6e",
    storageBucket: "georiddle-af49b.appspot.com",
    messagingSenderId: "944140708907",
    measurementId: "G-HNM4J42BZ3"
};

export let firebaseInstance : FirebaseApp | null = null
export let firestoreInstance : Firestore | null = null

export const initFirebase = async () => {
    firebaseInstance = initializeApp(config);
    firestoreInstance = getFirestore(firebaseInstance);
    await enableMultiTabIndexedDbPersistence(firestoreInstance)
}

export const getSingle = async <T extends object | null>(path: string) => {
    const docRef = doc(firestoreInstance!, path);
    const snap = await getDoc(docRef)
    if (snap.exists()) {
        return snap.data() as T
    } else {
        return null
    }
}

export const getMultiple = async <T extends object | null>(path: string) => {
    const docRef = collection(firestoreInstance!, path);
    const snap = await getDocs(docRef)
    const res: T[] = []
    snap.forEach(doc => {
        res.push(doc.data() as T)
    })
    return res
}

export const setSingle = async <T extends object>(path: string, data: T) => {
    const ref = doc(firestoreInstance!, path);
    return await setDoc(ref, data, {merge: true})
}

export const updateSingle = async <T extends object>(path: string, data: T) => {
    const ref = doc(firestoreInstance!, path);
    return await updateDoc(ref, data )
}

export const deleteSingle = async (path: string) => {
    const ref = doc(firestoreInstance!, path);
    return await deleteDoc(ref)
}
