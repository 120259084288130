import {useCallback, useRef, useState} from "react";
import {GeoResult, stopTracking, trackLocation} from "@packages/commons/src/geo/track";
import {IS_PRODUCTION} from "@packages/commons/src/env";

export const useTrackLocation = () => {
    const [locationInitialized, setLocationInitialized] = useState(false)
    const [isLocationLoading, setIsLocationLoading] = useState(false)
    const [locationError, setLocationError] = useState<string | null>(null)
    const promiseRef = useRef<Promise<GeoResult>>()
    const isCurrentlyMounted = useRef(true);

    const startLocalization = useCallback(() => {
        if (!IS_PRODUCTION) {
            setLocationInitialized(true)
            return
        }
        (async () => {
            setIsLocationLoading(true)
            try {
                promiseRef.current = trackLocation()
                await promiseRef.current
                isCurrentlyMounted.current && setLocationInitialized(true)
            } catch (e: any) {
                isCurrentlyMounted.current && setLocationError(e as string)
            } finally {
                isCurrentlyMounted.current && setIsLocationLoading(false)
            }
        })()
    }, [])

    const stopTrackingLocation = useCallback(() => {
        stopTracking()
    }, [])

    return {
        startLocalization, locationInitialized, isLocationLoading, locationError, stopTrackingLocation, promiseRef
    }
}