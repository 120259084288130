import {ReactNode, useLayoutEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";

import styles from './fullscreen.module.less'

export type FullscreenProps = {
    children: ReactNode
}

const Fullscreen = ({children}: FullscreenProps) => {
    const modalDiv = useRef<HTMLDivElement | null>(null)
    const [_, setParentCreated] = useState(false)

    useLayoutEffect(() => {
        const elem = document.createElement('div')
        elem.id = `fullscreen-modal`
        elem.className = styles.root
        document.body.appendChild(elem)
        modalDiv.current = elem
        setParentCreated(true)
        return () => {
            elem.remove()
        }
    }, [])


    if (!modalDiv.current) {
        return null
    }

    return createPortal(children, modalDiv.current)
}

export default Fullscreen