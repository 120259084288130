import React, {ReactNode} from 'react'

import styles from './boxy.module.less'
import clsx from "clsx";

type BoxyProps = {
    children: React.ReactNode
    className?: string
    label?: ReactNode
} & React.HTMLAttributes<HTMLDivElement>

const Boxy = ({children, className, label, ...rest}: BoxyProps) => {
    return (
        <div className={clsx(styles.root, className)} {...rest} >
            {
                label &&
                <div className={styles.label}>
                    {label}
                </div>
            }
            {children}
        </div>
    )
}

export {Boxy}