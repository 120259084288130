import {Coords, fitBounds} from "google-map-react";

export const calcDistance = (p1: Coords, p2: Coords): number => {
    return distance(p1.lat, p1.lng, p2.lat, p2.lng, "K") * 1000
}

type DistanceUnitType = "K" | "N" | "M"

function distance(lat1: number, lon1: number, lat2: number, lon2: number, unit: DistanceUnitType = "M") {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1/180;
        var radlat2 = Math.PI * lat2/180;
        var theta = lon1-lon2;
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit=="K") { dist = dist * 1.609344 }
        if (unit=="N") { dist = dist * 0.8684 }
        return dist;
    }
}

export type GeoLocation = ReturnType<typeof convert>

export const convert = ({coords: {latitude, longitude, heading, speed}}: GeolocationPosition) => {
    return {
        latitude,
        longitude,
        heading,
        speed,
        lat: latitude,
        lng: longitude
    }
}

export const getBounds = (coords: Coords[]) => {
    const latitudes = coords.map((c) => c.lat)
    const longitudes = coords.map((c) => c.lng)

    return {
        ne: {
            lat: Math.max.apply(null, latitudes),
            lng: Math.max.apply(null, longitudes),
        },
        sw: {
            lat: Math.min.apply(null, latitudes),
            lng: Math.min.apply(null, longitudes),
        },
    }
}

type MapSize = { width: number; height: number }

export const getCenter = (points: Coords[], mapSize: MapSize) => {
    if (points.length === 1) {
        return {
            zoom: 16,
            center: points[0],
        }
    }

    const bounds = getBounds(points)
    const { center, zoom } = fitBounds(bounds, mapSize)
    const minZoom = Math.min(zoom, 20)

    return {
        center, zoom: minZoom
    }
}

export const centerPoints = (map: google.maps.Map, points: Coords[], mapSize: MapSize) => {
    if (points.length === 1) {
        map.setZoom(16)
        map.setCenter(points[0])
        return
    }
    const {center, zoom} = getCenter(points, mapSize)
    map.setZoom(zoom)
    map.setCenter(center)
}