import React, {useEffect} from 'react'
import {Outlet} from "react-router-dom";

import styles from './layout.module.less'
import {trackLocation} from "@packages/commons/src/geo/track";
import {withFirstTime} from "@components/common/with-first-time";

type LayoutProps = {}

const Layout = withFirstTime(({}: LayoutProps) => {
    return (
        <div className={styles.root}>
            <div>
                <Outlet />
            </div>
        </div>
    )
})

export {Layout}