import React, {useEffect} from 'react'

import styles from './riddle-success.module.less'
import {Page} from "@components/common/page";
import {Button} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {PageHeader, PageSubheader} from "@packages/components/typography";
import {Box} from "@packages/components/layout/box";
import {AdventureProgress} from "@components/common/adventure-progress";

import {ReactComponent as Medal} from "@packages/components/icons/medal.svg";
import {ReactComponent as Star} from "@packages/components/graphics/star.svg";
import {SuccessButton} from "@packages/components/common/success-button";
import {Animated} from "react-animated-css";
import {play, SOUND} from "@packages/commons/src/sound";

type RiddleSuccessProps = {}

const RiddleSuccess = ({}: RiddleSuccessProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {adventureId, orgId } = useParams()
    const Footer = (<SuccessButton onClick={() => {
        play(SOUND.TAP)
        navigate(`/${orgId}/adventure/${adventureId}`)
    } } block >{t('riddle_success.back')}</SuccessButton>)

    return (
        <Page mode="gradient" className={styles.root} footer={Footer}>
            <Animated className={styles.mainIcon} animationInDelay={200} animationIn="bounceIn" animationOut="fadeOut" isVisible={true}>
                <Star />
            </Animated>
            <div className={styles.main}>
                <PageHeader size="large">
                    {t('riddle_success.header')}
                </PageHeader>
                <PageSubheader>
                    {t('riddle_success.description')}
                </PageSubheader>
            </div>
            <Box className={styles.box}>
                <Medal className={styles.medal} />
                <AdventureProgress className={styles.progress} />
            </Box>
        </Page>
    )
}

export {RiddleSuccess}