import React, {ReactNode} from 'react'

import styles from './info-box.module.less'
import {Box} from "@packages/components/layout/box";
import {ReactComponent as  QuestionMarkIcon} from "../icons/question-mark.svg";
import {Title} from "@packages/components/typography";
import clsx from "clsx";

type Mode = 'default' | 'inverted'

type InfoBoxProps = {
    children: ReactNode
    header?: ReactNode
    className?: string
    mode?: Mode
}

const InfoBox = ({children, header, className, mode = 'default'}: InfoBoxProps) => {
    if (!header) {
        return (
            <Box className={clsx(styles.root, styles.noHeader, styles[mode], className)}>
                <QuestionMarkIcon />
                <main>
                    {children}
                </main>
            </Box>
        )
    }

    return (
        <Box className={clsx(styles.root, className)}>
            <header>
                <QuestionMarkIcon />
                <Title>{header}</Title>
            </header>
            <main>
                {children}
            </main>
        </Box>
    )
}

export {InfoBox}