import React, {useCallback, useMemo, useState} from 'react'

import styles from './qr-scanner.module.less'
import Fullscreen from "@packages/components/layout/fullscreen";
import QrReader  from 'react-qr-reader';
import {PageHeader} from "@packages/components/typography";
import {useTranslation} from "react-i18next";
import {Button} from "antd";
import {Link} from "react-router-dom";
import {ReactComponent as Back} from "@packages/components/icons/back.svg";
import {BackButton} from "@packages/components/common/back-button";
import {Spinner} from "@packages/components/common/spinner";
import clsx from "clsx";

type QrOptions = {
    fullScreen?: boolean
}

export const useQrScanner = (callback: (code: string | null) => void, options?: QrOptions) => {
    const [show, setShow] = useState(false)
    const { t } = useTranslation("common")

    const {fullScreen } = {...{fullScreen: true}, ...options}
    const scan = useCallback(() => {
        setShow(true)
    }, [])

    const FullscreenComponent = fullScreen ? Fullscreen : 'div'

    const Component = useMemo(() => {
        return () => {
            if (show) {
                return (
                    <FullscreenComponent>
                        <div className={clsx(styles.qrWrapper, fullScreen && styles.fullScreen)}>
                            <div>
                                {
                                    fullScreen &&
                                    <PageHeader>
                                        {t('components.qr_scanner.header')}
                                    </PageHeader>
                                }
                                <main>
                                    <div>
                                        <Spinner />
                                    </div>
                                    <QrReader
                                        showViewFinder={false}
                                        delay={300}
                                        onError={(err) => {
                                            console.log(err)
                                            callback(null)
                                            setShow(false)
                                        }}
                                        onScan={(data) => {
                                            if (data) {
                                                callback(data)
                                                setShow(false)
                                            }
                                        }}
                                    />
                                    <svg viewBox="0 0 271 258" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M228.795 2.40642C228.795 1.07739 229.845 0 231.14 0H249.898C261.552 0 271 9.69652 271 21.6578V40.9092C271 42.2382 269.95 43.3156 268.655 43.3156C267.36 43.3156 266.311 42.2382 266.311 40.9092V21.6578C266.311 12.3546 258.962 4.81284 249.898 4.81284H231.14C229.845 4.81284 228.795 3.73545 228.795 2.40642Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.40642 43.3155C1.07739 43.3155 -4.70943e-08 42.2381 -1.05188e-07 40.9091L-9.46692e-07 21.6577C-1.46954e-06 9.69646 9.69652 -6.22339e-05 21.6578 -6.27567e-05L40.9092 -6.35982e-05C42.2382 -6.36563e-05 43.3156 1.07733 43.3156 2.40636C43.3156 3.73539 42.2382 4.81278 40.9092 4.81278L21.6578 4.81278C12.3546 4.81278 4.81284 12.3545 4.81284 21.6577L4.81284 40.9091C4.81284 42.2381 3.73545 43.3155 2.40642 43.3155Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M268.655 215.467C269.95 215.467 271 216.517 271 217.812L271 236.57C271 248.224 261.552 257.672 249.898 257.672L231.14 257.672C229.845 257.672 228.795 256.622 228.795 255.327C228.795 254.032 229.845 252.983 231.14 252.983L249.898 252.983C258.962 252.983 266.311 245.634 266.311 236.57L266.311 217.812C266.311 216.517 267.36 215.467 268.655 215.467Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M43.3156 255.327C43.3156 256.622 42.2658 257.672 40.9708 257.672L22.2131 257.672C10.5585 257.672 1.11063 248.224 1.11063 236.57L1.11063 217.812C1.11063 216.517 2.1604 215.467 3.45535 215.467C4.7503 215.467 5.80006 216.517 5.80006 217.812L5.80006 236.57C5.80006 245.634 13.1484 252.983 22.2131 252.983L40.9708 252.983C42.2658 252.983 43.3156 254.033 43.3156 255.327Z" fill="white" />
                                    </svg>
                                </main>
                                {
                                    fullScreen &&
                                    <BackButton onClick={() => {
                                        setShow(false)
                                    }}>
                                        {t('cancel')}
                                    </BackButton>
                                }
                            </div>
                        </div>
                    </FullscreenComponent>
                )
            } else return <></>
        }
    }, [show, callback])
    return {
       scan,
       Scanner: Component,
       show,
       cancel: () => {setShow(false)}
    }
}