export const undefinedToNull = <T extends object>(obj: T) => {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value !== undefined ? value : null])) as T
}

export const createId = (id: string) => {
    return id.replace(/[^a-zA-Z0-9 ]/g, '')
            .replace(/[^\x00-\x7F]/g, '')
            .replace(/\s+/g, '_')
            .replace(/\W+/g, '')
            .toLowerCase()
}

export function makeId(length: number) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export function makeNumberId(length: number) {
    var result           = '';
    var characters       = '0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}