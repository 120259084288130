import {Howl, Howler} from 'howler';

export const SOUND = {
    TAP: new Howl({
        volume: 0.2,
        src: ['/tap.mp3']
    }),
    SUCCESS: new Howl({
        volume: 0.5,
        src: ['/hurray.mp3']
    }),
    ERROR: new Howl({
        volume: 0.5,
        src: ['/error.mp3']
    }),
}

export const play = (howl: Howl) => {
    howl.play()
}
