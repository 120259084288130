import React, { Component } from 'react'
import { Coords } from 'google-map-react'

import styles from './marker.module.less'

export const createMarker = <P extends Coords>(Comp: React.ComponentType<P>) => {
    return class LocationWrapper extends Component<P> {
        render() {
            const props = this.props
            return (
                <div className={styles.marker}>
                    <Comp {...(props as P)} />
                </div>
            )
        }
    }
}
