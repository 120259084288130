import './styles/global.less'

import React from "react";
import ReactDOM from "react-dom";
import {App} from "@components/app";

import {RecoilRoot} from "recoil";
import {BrowserRouter} from "react-router-dom";

import {initFirebase} from "@packages/commons/src/services/firebase";
import {queryClient} from "@packages/commons/src/services/react-query";
import {QueryClientProvider} from "react-query";
import {init} from './i18n/config';
import {withSentry} from "@packages/components/services/sentry";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {Offline} from "@components/common/offline";

init()

const AppContainer = () =>
    <React.StrictMode>
        <BrowserRouter>
            <RecoilRoot>
                <QueryClientProvider client={queryClient} >
                    <>
                        <Offline />
                        <App />
                    </>
                </QueryClientProvider>
            </RecoilRoot>
        </BrowserRouter>
    </React.StrictMode>

const AppWithSentry = withSentry(AppContainer, 'https://044ecffe120a46eab5f3916f02d632f0@o1375284.ingest.sentry.io/6683511')

initFirebase().then(() => {
    ReactDOM.render(
        <AppWithSentry />,
        document.getElementById("root")
    );
})


serviceWorkerRegistration.register({
    onUpdate: (registration: any) => {
        console.log('updating web worker')
        registration && registration.waiting && registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
    }
});