import React from 'react'

import styles from './not-found.module.less'
import {Centered} from "@packages/components/layout/centered";
import {Button, Result} from "antd";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

type NotFoundProps = {
    headerText?: string
    link?: string
}

const NotFound = ({headerText, link}: NotFoundProps) => {
    const { t } = useTranslation()

    return (
        <Centered>
            <Result
                status="404"
                title="404"
                subTitle={headerText || t('no_match.header')}
                extra={<Link to={link || '/'}>
                    <Button type="primary">{t('no_match.back')}</Button>
                </Link>}
            />
        </Centered>
    )
}

export {NotFound}