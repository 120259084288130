import {useEffect, useState} from "react";
import {locationRef} from "@packages/commons/src/geo/track";
import {Coords} from "google-map-react";
import {GeoLocation} from "@packages/commons/src/geo";

export const useLocation = (timeout?: number) => {
    const [location, setLocation] = useState<GeoLocation | null>(locationRef.current)
    useEffect(() => {
        setLocation(locationRef.current)
        const interval = setInterval(() => {
            setLocation(locationRef.current)
        }, timeout || 5000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    return location
}