import React, {
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef
} from 'react'

import styles from './with-first-time.module.less'
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useLocalStorage} from "usehooks-ts";
import {VISIT} from "../../types/domain";
import {WelcomeScreen, WelcomeScreenProps} from "@packages/components/layout/welcome-screen";
import {PageHeader, PageSubheader} from "@packages/components/typography";
import {SuccessButton} from "@packages/components/common/success-button";
import {Spinner} from "@packages/components/common/spinner";
import {useTrackLocation} from "@packages/components/hooks/useTrackLocation";
import {IS_PRODUCTION} from "@packages/commons/src/env";
import {InfoBox} from "@packages/components/layout/info-box";
import {motion, AnimatePresence} from "framer-motion"
import {Simulate} from "react-dom/test-utils";
import {SOUND, play} from "@packages/commons/src/sound";

export type WithFirstTimeProps = {}

const WelcomeWrapper = ({children, header}: { children: ReactNode, header?: ReactNode }) => {
    const {t} = useTranslation()

    return (
        <motion.div
            style={{height: '100%', zIndex: -1}}
            key={"WelcomeScreen"}
            initial={{opacity: 1, scale: 1}}
            animate={{opacity: 1, scale: 1, rotate: 0}}
            exit={{opacity: 0.1, height: 0}}
            transition={{
                duration: 0.7,
                type: "tween",
            }}
        >
            <WelcomeScreen>
                <main className={styles.welcome}>
                    <section>
                        <PageHeader size="very-large">{t('welcome_screen.header')}</PageHeader>
                        <PageSubheader size="large">{t('welcome_screen.subheader')}</PageSubheader>
                        {
                            header
                        }
                    </section>
                    {children}
                </main>
            </WelcomeScreen>
        </motion.div>
    )
}

export const withFirstTime = <T extends WithFirstTimeProps>(Component: React.ComponentType<T>) => (props: T) => {
    let {orgId, code} = useParams();
    const {t} = useTranslation()
    const [firstTime, setFirstTime] = useLocalStorage<VISIT>(`firsttime-${orgId}`, 'first-time')
    const isFirstTime = firstTime === 'first-time';
    const {startLocalization, locationInitialized, isLocationLoading, locationError} = useTrackLocation()

    const isVeryFistTime = useMemo(() => {
        return isFirstTime
    }, [])

    const start = useCallback(async () => {
        play(SOUND.TAP)
        startLocalization()
        setFirstTime('visited')
    }, [setFirstTime])

    useEffect(() => {
        if (!isFirstTime) {
            startLocalization()
        }
    }, [])

    const firstTimeComponent = (() => {
        if (isFirstTime || (isVeryFistTime && isLocationLoading && IS_PRODUCTION)) {
            return (<WelcomeWrapper header={<InfoBox
                header={t('welcome_screen.location_info_header')}>{t('welcome_screen.location_info')}</InfoBox>}>
                <SuccessButton withShadow block loading={isLocationLoading} size="large"
                               onClick={start}>{t('welcome_screen.start')}</SuccessButton>
            </WelcomeWrapper>)
        }
        if (IS_PRODUCTION) {
            if (isLocationLoading) {
                return (<Spinner centered/>)
            }
            if (!locationInitialized) {
                return (
                    <WelcomeWrapper>
                        {t('welcome_screen.location_error')}
                        {locationError}
                    </WelcomeWrapper>
                )
            }
        }
        return null;
    })()

    return (
        <>
            <AnimatePresence>
                {firstTimeComponent}
            </AnimatePresence>
            <Component {...props} />
        </>
    )
}