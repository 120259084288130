import {atom} from "recoil";
import {AdventureStatus, Riddle} from "@packages/types/domain";

type ArrayElement<A> = A extends readonly (infer T)[] ? T : never

export const mapSelectedRiddleAtom = atom<Riddle | null>({
    key: 'map-selected-riddle',
    default: null,
});

export const selectedRiddleAtom = atom<Riddle | null>({
    key: 'selected-riddle',
    default: null,
});

export const adventuresStatus = atom<{[key: string]: AdventureStatus} | null>({
    key: 'adventures-status',
    default: {},
});

export const networkStatus = atom<boolean>({
    key: 'network-status',
    default: navigator.onLine,
});