import React, {useCallback, useEffect, useRef} from 'react'
import {notification} from "antd";
import {useTranslation} from "react-i18next";

import EventEmitter from 'events'
import {useSetRecoilState} from "recoil";
import {networkStatus} from "../../domain/state";

const events = new EventEmitter()

type OfflineProps = {}

type Timeout = ReturnType<typeof setTimeout>;

function isReachable(url: string) {
    return fetch(url, { method: 'HEAD', mode: 'no-cors' })
        .then(function(resp) {
            return resp && (resp.ok || resp.type === 'opaque');
        })
        .catch(function(err) {
            console.warn('[conn test failure]:', err);
        });
}

const debounce = (func: () => void, wait: number) => {
    let timeout: Timeout | null = null;
    return function executedFunction() {
        const later = () => {
            timeout = null;
            func();
        };
        timeout && clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

const Offline = ({}: OfflineProps) => {
    const { t } = useTranslation();
    const state = useRef(navigator.onLine)
    const setRecoilStatus = useSetRecoilState(networkStatus)

    const offlineCallback = () => {
        const args = {
            message: t('offline.offline'),
            duration: 0,
        };
        notification.error(args);
        state.current = false;
        setRecoilStatus(state.current)
    }

    const onlineCallback = () => {
        const args = {
            message: t('offline.online_mode'),
            duration: 0,
        };
        notification.success(args);
        state.current = true;
        setRecoilStatus(state.current)
    }

    useEffect(() => {
        if (!navigator.onLine) {
            offlineCallback()
        }
    }, [])

    const handleNetworkChange = useCallback(() => {
        if (document.hidden) {
            return
        }

        if (!state.current && navigator.onLine) {
            //back online
            isReachable("https://www.google.com").then(() => {
                onlineCallback()
            })
        } else if (state.current && !navigator.onLine) {
            offlineCallback()
        }
    }, []);

    const emit = useCallback(() => {
        events.emit("event")
    }, [])


    useEffect(() => {
        const debouncedFunc = debounce(handleNetworkChange, 2000)
        events.on('event', debouncedFunc)

        window.addEventListener('online', emit);
        window.addEventListener('offline', emit);
        window.addEventListener('focus', emit);

        return () => {
            events.removeAllListeners()
            window.removeEventListener('online', emit);
            window.removeEventListener('offline', emit);
            window.removeEventListener('focus', emit);
        };
    }, []);

    return (
        <>
        </>
    )
}

export {Offline}

