import React from 'react'

import styles from './no-organization.module.less'
import {Box} from "@packages/components/layout/box";
import {Label, PageHeader, PageSubheader} from "@packages/components/typography";
import {SuccessButton} from "@packages/components/common/success-button";
import {WelcomeScreen} from "@packages/components/layout/welcome-screen";
import {useTranslation} from "react-i18next";
import {InfoBox} from "@packages/components/layout/info-box";

type NoOrganizationProps = {}

const NoOrganization = ({}: NoOrganizationProps) => {
    const { t } = useTranslation()

    return (
        <WelcomeScreen>
            <main>
                <section>
                    <PageHeader size="very-large">{t('welcome_screen.header')}</PageHeader>
                    <PageSubheader size="large">{t('welcome_screen.subheader')}</PageSubheader>
                    <InfoBox  >
                        {t('no_org.info')}
                    </InfoBox>
                </section>
            </main>
        </WelcomeScreen>
    )
}

export {NoOrganization}
