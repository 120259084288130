import React, {Dispatch, ReactNode, SetStateAction, useEffect, useState} from 'react'

import styles from './welcome-screen.module.less'
import clsx from "clsx";
import {ReactComponent as Trees} from "@packages/components/graphics/trees.svg";

export type WelcomeScreenProps = {
    children: ReactNode,
    className?: string
}

const WelcomeScreen = ({children, className}: WelcomeScreenProps) => {
    return (
        <div className={clsx(styles.root, className)}>
            <Trees className={styles.trees} />
            {children}
        </div>
    )
}

export {WelcomeScreen}