import {useLocalStorage} from "usehooks-ts";
import {useParams} from "react-router-dom";
import {Adventure, AdventureStatus, Status} from "@packages/types/domain";
import {getAllRiddlesLength, getFinishedRiddles} from "@packages/commons/src/domain";
import {addPrize} from "@hooks/useFirestore";
import {Dispatch, SetStateAction} from "react";

const createKey = (orgId: string| undefined) => `status-v1-${orgId}`

export const useDeleteStatus = () => {
    const {orgId} = useParams()
    localStorage.removeItem(createKey(orgId))
}

const DEFAULT_STATE = {
    id: '',
    riddles: {},
    finishedCount: 0,
    lastFinishDate: null,
}

type PassRiddleResult = {
    adventure: AdventureStatus,
    isAdventurePassed: boolean
}

type SetValue<T> = Dispatch<SetStateAction<T>>;

const useLocalStoreStatus = (orgId: string) => {
    const [status, setStatus] = useLocalStorage<Status>(createKey(orgId), {})

    const mappedStatus: Status = {}
    Object.keys(status).forEach((key) => {
        const adventure = status[key]
        const prize = adventure?.prize ? {...adventure.prize, expiresAt: new Date(adventure.prize.expiresAt) } : undefined
        const newAdventure = {
            ...adventure!,
            prize
        }
        mappedStatus[key] = newAdventure
    })

    return [
        mappedStatus,
        setStatus
    ] as [Status, SetValue<Status>]
}

export const usePassRiddle = (adventure: Adventure, optionalRiddleId?: string) => {
    const {orgId, ...params } = useParams()
    const [status, setStatus] = useLocalStoreStatus(orgId!)
    const riddleId = optionalRiddleId || params.riddleId

    return async (): Promise<PassRiddleResult> => {
        if (!riddleId) {
            throw 'No params'
        }

        const oldAdventure = status[adventure.id] || DEFAULT_STATE

        const newRiddles = {
            ...oldAdventure.riddles,
            [riddleId]: {
                finishDate: new Date().toString(),
            }
        }

        const finishedRiddles = getFinishedRiddles(adventure, newRiddles)
        const allRiddles = getAllRiddlesLength(adventure)
        const isAdventurePassed = finishedRiddles === allRiddles

        const summary = isAdventurePassed ? {
            finishedCount: (oldAdventure.finishedCount || 0) + 1,
            lastFinishData: new Date().toString(),
        } : {}

        const prize = await (async () => {
            if (adventure.showPrize && isAdventurePassed) {
                const { expiresAt, code } = await addPrize(orgId!, adventure.id)
                return {expiresAt, code}
            }
            return undefined
        })()

        const newAdventure = {
            ...oldAdventure,
            riddles: newRiddles,
            ...summary,
            prize,
            id: adventure.id,
        }

        setStatus({
            ...status,
            [adventure.id]: newAdventure
        })

        return {
            adventure: newAdventure,
            isAdventurePassed,
        }
    }
}

export const useStatus = () => {
    const {orgId} = useParams()
    return useLocalStoreStatus(orgId!)
}

export const useAdventureStatus = (): AdventureStatus => {
    const {orgId, adventureId} = useParams()
    const [status] = useLocalStoreStatus(orgId!)
    return (adventureId && status[adventureId]) || DEFAULT_STATE
}

export const useResetAdventureStatus = () => {
    const {adventureId} = useParams()
    const [status, setStatus] = useStatus()
   return (id?: string) => {
       setStatus({
           ...status,
           [id || adventureId!]: {
               ...status[id || adventureId!]!,
               riddles: {},
               prize: undefined,
           }
       })
   }
}

export const useAdventureProgress = (adventure: Adventure) => {
    const status = useAdventureStatus()
    const finishedRiddles = getFinishedRiddles(adventure, status.riddles)
    const allRiddles = getAllRiddlesLength(adventure)

    return {
        finishedRiddles,
        allRiddles
    }
}