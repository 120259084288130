import {Spin} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

import styles from './spinner.module.less'
import {Centered} from "@packages/components/layout/centered";

export type TipMode = 'all' | 'only-spinner' | 'only-label'

export type SpinnerProps = {
    centered?: boolean
    tipMode?: TipMode,
}

export const Spinner = ({centered = false, tipMode = 'all'}: SpinnerProps) => {
    const { t } = useTranslation()
    const tip = (tipMode !== 'only-spinner') ? t('loading', {ns: 'common'}) : undefined

    if (tipMode === 'only-label') {
        return <span>{tip}...</span>
    }

    if (centered) {
        return <Centered>
            <Spin tip={tip} />
        </Centered>
    }/**/

    return (
        <Spin tip={tip} />
    )
}

