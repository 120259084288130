import React from 'react'

import styles from './box.module.less'
import clsx from "clsx";

type BoxProps = {
    children: React.ReactNode
    className?: string
} & React.HTMLAttributes<HTMLDivElement>

const Box = ({children, className, ...rest}: BoxProps) => {
    return (
        <div className={clsx(styles.root, className)} {...rest} >
            {children}
        </div>
    )
}

export {Box}