import React from 'react'

import styles from './riddle-summary.module.less'
import {Riddle} from "@packages/types/domain";
import {useTranslation} from "react-i18next";
import {Label, Title} from "@packages/components/typography";
import {Distance} from "@packages/components/geo/distance";
import {Boxy} from "@packages/components/layout/boxy";
import {DifficultyComponent} from "@packages/components/difficulty";
import {RiddleTypeComponent} from "@packages/components/riddle/riddle-type";
import {isCoords, toMapRiddle} from "@packages/commons/src/domain";
import {ReactComponent as Back} from "@packages/components/icons/back.svg";
import {ReactComponent as ChevronDown} from "@packages/components/icons/chevron-down.svg";
import {Link} from "react-router-dom";
import {Button} from "antd";
import {CloseCircleOutlined} from "@ant-design/icons";

type RiddleSummaryProps = {
    riddle: Riddle
    onClose?: () => void
    withInfo?: boolean
    backLink?: string
    noHeader?: boolean
}

const RiddleSummary = ({riddle, onClose, backLink, noHeader = false}: RiddleSummaryProps) => {
    const {t} = useTranslation();
    return (
        <div className={styles.root}>
            {
                !noHeader &&
                <header>
                    <Title size="large" className={styles.leftHeader}>
                        {
                            backLink &&
                            <Link to={backLink}>
                                <Back className={styles.backIcon} />
                            </Link>
                        }
                        {riddle.name}
                    </Title>
                    <div className={styles.rightHeader}>
                        {
                            isCoords(riddle) && <Distance {...toMapRiddle(riddle)} />
                        }
                        {
                            onClose &&
                            <div onClick={onClose} className={styles.closeIcon}>
                                <CloseCircleOutlined />
                            </div>
                        }
                    </div>
                </header>
            }
            <section>
                <Boxy label={<Label>{t('difficulty', {ns: 'common'})}</Label>}>
                    <DifficultyComponent difficulty={riddle.difficulty}/>
                </Boxy>
                <Boxy label={<Label>{t('type', {ns: 'common'})}</Label>} >
                    <RiddleTypeComponent withLabel type={riddle.type}/>
                </Boxy>
            </section>
        </div>
    )
}

export {RiddleSummary}