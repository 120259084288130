import React, {useCallback, useEffect, useState} from 'react'
import {Button, Collapse, Input, notification} from "antd";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    withAdventure,
    WithAdventureProps,
    withAdventures,
    WithAdventuresProps
} from "@components/common/with-adventures";
import {Page} from "@components/common/page";
import {useTranslation} from "react-i18next";
import {Map} from '@components/map'
import {RiddleSummary} from "@components/common/riddle-summary";

import styles from './riddle.module.less'
import {Carousel} from 'antd';
import {Image} from 'antd';
import {useQrScanner} from "@packages/components/qr-scanner";
import {ReactComponent as BackIcon, ReactComponent as Back} from "@packages/components/icons/back.svg";
import {usePassRiddle} from "@hooks/useStatus";
import {IS_PRODUCTION} from "@packages/commons/src/env";
import {getCodeFromUrl, isCoords, isDescriptionValid, toMapRiddle} from "@packages/commons/src/domain";
import {calcDistance} from "@packages/commons/src/geo";
import {locationRef} from "@packages/commons/src/geo/track";
import clsx from "clsx";
import {Box} from "@packages/components/layout/box";
import {NotFound} from "@packages/components/status/not-found";
import {createId} from "@packages/commons/src/object";
import {SuccessButton} from "@packages/components/common/success-button";
import {Distance} from "@packages/components/geo/distance";
import {ReactComponent as Star} from "@packages/components/graphics/star.svg";
import {Boxy} from "@packages/components/layout/boxy";
import {ExclamationCircleTwoTone} from "@ant-design/icons";
import fullscreen from "@packages/components/layout/fullscreen";
import {BackButton} from "@packages/components/common/back-button";
import useSound from "use-sound";
import {SwipeEventData, useSwipeable} from "react-swipeable";
import {throttle} from "@packages/commons";
import {motion} from "framer-motion"
import {SOUND, play} from "@packages/commons/src/sound";

type RiddleProps = {} & WithAdventureProps

const transformPhotos = (photoUrl: string | null | undefined) => {
    if (!photoUrl) {
        return []
    }

    const split = photoUrl.split('~')
    const amount = parseInt(split[1])

    return Array.from({length: amount}, (x, i) => {
        return `https://ucarecdn.com/${photoUrl}/nth/${i}/`
    });
}

type PictureCarouselProps = {
    photos: string[]
}

const PictureCarousel = ({photos}: PictureCarouselProps) => {
    if (photos.length === 0) {
        return null;
    }
    return (
        <Carousel>
            {photos.map(photo => {
                return <div key={photo} className={styles.imageContainer}>
                    <Image src={`${photo}-/resize/400x/`} preview={{
                        src: photo,
                    }}/>
                </div>
            })}
        </Carousel>
    )
}

const showNotification = (message: string, description?: string) => {
    notification.info({
        duration: 3,
        top: 95,
        icon: <ExclamationCircleTwoTone style={{fontSize: '20px'}} twoToneColor="#FF5D5B"/>,
        className: `${styles.notification} ${description ? "" : styles.notificationWithoutDescription}`,
        message,
        description,
        placement: 'topRight',
    });
}

let lastHeight = 200

const Riddle = withAdventure(({adventure}: RiddleProps) => {
    const {t} = useTranslation();
    const {adventureId, orgId, riddleId} = useParams()
    const riddle = adventure.riddles?.find(r => r.id === riddleId)
    const navigate = useNavigate();
    const passRiddle = usePassRiddle(adventure)
    const [textCode, setTextCode] = useState('')
    const [isPassing, setIsPassing] = useState(false)
    const [isHintOpen, setIsHintOpen] = useState(false)
    const [mapHeight, setMapHeight] = useState(200)
    const [isSwiping, setIsSwiping] = useState(false)

    const successCallback = async () => {
        setIsPassing(true)
        try {
            const {isAdventurePassed} = await passRiddle()
            play(SOUND.SUCCESS)
            if (isAdventurePassed) {
                navigate(`/${orgId}/adventure/${adventureId}/success`)
            } else {
                navigate('success')
            }
        } catch (e) {
        }
    }

    const getDistance = () => isCoords(riddle) ? calcDistance(toMapRiddle(riddle!), locationRef.current!) : null

    const checkTextCode = () => {
        if (createId(textCode) !== createId(riddle?.code!)) {
            play(SOUND.ERROR)
            showNotification(t('riddle.wrong_code'))
            return
        }
        successCallback()
    }

    const checkPlace = () => {
        const distance = getDistance()
        if (distance && distance < (riddle?.placeRadius || 5)) {
            successCallback()
        } else {
            play(SOUND.ERROR)
            showNotification(t('riddle.big_distance'), t('riddle.big_distance_description'))
            return
        }
    }

    const {scan, Scanner, show: showScanner, cancel} = useQrScanner((data) => {
        if (!data || !riddle) {
            return;
        }
        try {
            const code = getCodeFromUrl(data)
            const distance = getDistance()
            if (code !== riddle?.code) {
                play(SOUND.ERROR)
                showNotification(t('riddle.wrong_code'), t('riddle.wrong_code_description'))
                return
            }
            if (!distance || distance > 15) {
                play(SOUND.ERROR)
                showNotification(t('riddle.big_distance'), t('riddle.big_distance_description'))
                return
            }
            successCallback()
        } catch (e) {
            showNotification(t('riddle.bad_code'), t('riddle.bad_code_description'))
        }
    }, {fullScreen: false})

    const swipingCallback = useCallback(throttle((e: SwipeEventData) => {
        const newValue = e.deltaY + lastHeight;
        setMapHeight(newValue)
    }, 50), [])

    const handlers = useSwipeable({
        onSwiping: swipingCallback,
        onSwiped: () => {
            setIsSwiping(false)
            setTimeout(() => {
                const fixHeight = document.querySelector("#mapContainer")?.getBoundingClientRect().height || null
                if (fixHeight) {
                    lastHeight = fixHeight
                }
            }, 100)
        },
        onSwipeStart: () => {
            setIsSwiping(true)
        },
        ...{
            delta: 10,                             // min distance(px) before a swipe starts. *See Notes*
            preventScrollOnSwipe: true,           // prevents scroll during swipe (*See Details*)
            trackTouch: true,                      // track touch input
            trackMouse: false,                     // track mouse input// set a rotation angle
            swipeDuration: Infinity,
        },
    });

    if (!riddle) {
        return (
            <NotFound headerText={t('riddle_not_found')} link={`/${orgId}/adventure/${adventureId}`}/>
        )
    }

    const Header = (
        <div className={clsx(styles.header)}>
            <div>
                <Link onClick={() => {
                    play(SOUND.TAP)
                }} to={`/${orgId}/adventure/${adventureId}`}>
                    <BackIcon className={styles.backIcon}/>
                </Link>
                {riddle?.name}
            </div>
            <div className={styles.headerRightPanel}>
                {
                    isCoords(riddle) && <Distance {...toMapRiddle(riddle)} />
                }
                {
                    !IS_PRODUCTION &&
                    <span onClick={successCallback}>Win</span>
                }
            </div>
        </div>
    )

    const Footer = (<div>
        {
            (riddle.type === 'place') &&
            <SuccessButton loading={isPassing} onClick={checkPlace} block>{t('riddle.check_place')}</SuccessButton>
        }
        {
            (riddle.type === undefined || riddle.type === 'qr_code') &&
            <>
                {
                    showScanner && <BackButton block onClick={() => {
                        play(SOUND.TAP)
                        cancel()
                    }
                    }>{t('cancel', {ns: 'common'})}</BackButton>
                }
                {
                    !showScanner &&
                    <SuccessButton loading={isPassing} onClick={() => {
                        scan()
                        play(SOUND.TAP)
                    }
                    } block>{t('riddle.scan_code')}</SuccessButton>
                }
            </>
        }
        {
            riddle.type === 'text_code' &&
            <div className={styles.codeInput}>
                <Input placeholder={t('riddle.code_placeholder')} value={textCode}
                       onChange={(val) => setTextCode(val.target.value)}></Input>
                <SuccessButton loading={isPassing} onClick={checkTextCode}>{t('riddle.input_code')}</SuccessButton>
            </div>
        }
    </div>)

    const photos = transformPhotos(riddle.photos)
    const hasLocation = isCoords(riddle)
    const hintPhotos = transformPhotos(riddle.hintPhotos)

    const riddleContent = <motion.div initial={{x: 500}} animate={{x: 0}} transition={{duration: 0.5}}>
        {
            isDescriptionValid(riddle.description) &&
            <motion.div
                className={styles.description} dangerouslySetInnerHTML={{__html: riddle.description || ''}}/>
        }
        <PictureCarousel photos={photos}/>
        {
            riddle.showHint && (isHintOpen
                ? <motion.div initial={{scaleX: 0}} animate={{scaleX: 1}}>
                    {
                        isDescriptionValid(riddle.hint) &&
                        <div className={styles.description} dangerouslySetInnerHTML={{__html: riddle.hint!}}/>
                    }
                    <PictureCarousel photos={hintPhotos}/>
                </motion.div>
                : <Boxy onClick={() => setIsHintOpen(true)} className={styles.hint}>{t('riddle.hint')}</Boxy>)
        }
    </motion.div>

    return (
        <Page headerSize="small" mode="default" footer={Footer} header={Header}
              className={clsx(styles.root, hasLocation && styles.map, riddle.type === 'text_code' && styles.textCode, showScanner && styles.centerMain)}>
            {
                !showScanner &&
                <>
                    {
                        hasLocation &&
                        <>
                            <section id="mapContainer" style={{height: `${mapHeight}px`}}>
                                <Map centerWithLocation riddles={[{...(toMapRiddle(riddle)), finished: false}]}
                                     selectedRiddleId={riddle.id || null}/>
                            </section>
                        </>
                    }
                    <div className={clsx(styles.main)}>
                        <div {...handlers}>
                            <svg width="73" height="4" viewBox="0 0 73 4" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.232025" width="72.536" height="3.21346" rx="1.60673"
                                      fill={isSwiping ? 'darkgray' : "#DCEBEA"}/>
                            </svg>
                        </div>
                        <RiddleSummary noHeader={true} riddle={riddle}/>
                        {riddleContent}
                    </div>
                </>
            }
            {
                riddle.type === 'qr_code' && showScanner &&
                <Scanner/>
            }
        </Page>
    );
})

export {Riddle}