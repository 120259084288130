import { useMediaQuery } from 'react-responsive';
// @ts-ignore
import resolveConfig from 'tailwindcss/resolveConfig'

const fullConfig = resolveConfig({});
const breakpoints = fullConfig.theme.screens;

export function useBreakpoint(breakpointKey: string) {
    const bool = useMediaQuery({
        query: `(min-width: ${breakpoints[breakpointKey]})`,
    });
    // @ts-ignore
    const capitalizedKey = breakpointKey[0].toUpperCase() + breakpointKey.substring(1);
    return {
        [`is${capitalizedKey}`]: bool,
    } as Record<string, boolean>;
}