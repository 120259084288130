import React, {
    ComponentType,
    ForwardRefExoticComponent,
    FunctionComponent,
    HTMLAttributes,
    ReactComponentElement,
    SVGProps
} from 'react'

import styles from './riddle-type.module.less'
import {QrcodeOutlined, CodeOutlined, GlobalOutlined} from "@ant-design/icons";
import {RiddleType} from "@packages/types/domain";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {useBreakpoint} from "@packages/components/hooks/useBreakpoint";
import {AntdIconProps} from "@ant-design/icons/lib/components/AntdIcon";
import {ReactComponent as Place} from "@packages/components/icons/riddle-types/place.svg";
import {ReactComponent as Qr} from "@packages/components/icons/riddle-types/qr.svg";
import {ReactComponent as Code} from "@packages/components/icons/riddle-types/code.svg";

type RiddleTypeComponentProps = {
    withLabel?: boolean
    type?: RiddleType
}

type IconType = FunctionComponent<SVGProps<SVGSVGElement>>

const TypeToComponentMapping: {[key in RiddleType]: IconType} = {
    'text_code': Code,
    'qr_code': Qr,
    'place': Place,
}

const RiddleTypeComponent = ({type = 'qr_code', withLabel = false}: RiddleTypeComponentProps) => {
    const {t} = useTranslation()
    const {isSm} = useBreakpoint("sm")
    const Component = TypeToComponentMapping[type]
    return (
        <div className={clsx(styles.root, withLabel && isSm && styles.minWidth)}>
            {
                <Component className={styles.typeIcon} />
            }
            {
                withLabel &&
                <span>{t(`riddle_type.${type}` , {ns: 'common'})}</span>
            }
        </div>
    )
}

export {RiddleTypeComponent}