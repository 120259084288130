import React, {useEffect} from 'react'

import styles from './find-by-code.module.less'
import {useTranslation} from "react-i18next";
import {PageHeader, PageSubheader} from "@packages/components/typography";
import {Link, useNavigate, useParams} from "react-router-dom";
import {findRiddleByCode} from "@packages/commons/src/domain";
import {usePassRiddle} from "@hooks/useStatus";
import {withAdventures, WithAdventuresProps} from "@components/common/with-adventures";
import {Page} from "@components/common/page";
import {SuccessButton} from "@packages/components/common/success-button";
import {Spinner} from "@packages/components/common/spinner";
import {Animated} from "react-animated-css";
import {ReactComponent as Treasure} from "@packages/components/graphics/treasure.svg";
import {play, SOUND} from "@packages/commons/src/sound";

type FindByCodeProps = {} & WithAdventuresProps

const FindByCode = withAdventures(({adventures}: FindByCodeProps) => {
    const { t } = useTranslation()
    let { code, orgId } = useParams();
    const {riddle, adventure} = findRiddleByCode(code!, adventures)
    const passRiddle = (adventure && riddle) && usePassRiddle(adventure, riddle.id)
    const navigate =  useNavigate()

    useEffect(() => {
        if (!riddle) {
            return
        }
        (async () => {
            //TODO - check if all statuses passed and we will be redoing the adventure
            const res = await passRiddle?.()
            const prefix = `/${orgId}/adventure/${adventure?.id}`
            const link = res?.isAdventurePassed ? `${prefix}/success`: `${prefix}/riddle/${riddle.id}/success`
            navigate(link)
        })()
    }, [])

    const Footer = (<Link to={`/${orgId}`}>
        <SuccessButton block onClick={() => {
            play(SOUND.TAP)
        }} >{t('find_by_code.go_to_adventures')}</SuccessButton>
    </Link>)

    if (!riddle) {
        return (
            <Page mode="gradient" className={styles.root} footer={Footer}>
                <Animated className={styles.mainIcon} animationInDelay={200} animationIn="bounceIn" animationOut="fadeOut" isVisible={true}>
                    <Treasure />
                </Animated>
                <div className={styles.main}>
                    <PageHeader size="large">
                        {t('find_by_code.header')}
                    </PageHeader>
                    <PageSubheader>
                        {t('find_by_code.description')}
                    </PageSubheader>
                </div>
            </Page>
        )
    }

    return (
        <Page mode="gradient" >
            <Spinner centered />
        </Page>
    )
})

export {FindByCode}