import React from 'react'

import styles from './adventure-progress.module.less'
import {Navigate, useParams} from "react-router-dom";
import {
    withAdventure,
    WithAdventureProps,
    withAdventures,
    WithAdventuresProps
} from "@components/common/with-adventures";
import {useAdventureProgress, useAdventureStatus} from "@hooks/useStatus";
import {ReactComponent as Reward} from "@packages/components/icons/reward.svg";
import {Progress} from "antd";
import clsx from "clsx";
import {Number, TypoPropsWithSize} from "@packages/components/typography";
import {getFinishedRiddles} from "@packages/commons/src/domain";

type Mode = 'default' | 'inverted'

type AdventureProgressProps = {
    className?: string,
    showBar?: boolean,
    percentage?: boolean
} & WithAdventureProps & WithAdventuresProps & {mode?: Mode} & {size?: TypoPropsWithSize["size"]}

const AdventureProgress = withAdventure<AdventureProgressProps>(({percentage=false, adventure, className, mode = "default", showBar = true, size="small"}: AdventureProgressProps) => {
    const {allRiddles, finishedRiddles} = useAdventureProgress(adventure)
    const perc = Math.round((finishedRiddles/allRiddles) * 100)

    const trailColor = mode === 'default' ? "#DCEBEA" : "rgba(235, 253, 252, 0.15)"

    return (
        <div className={clsx(styles.root, styles[mode], className)}>
            {
                allRiddles &&
                <>
                    {
                        showBar &&
                        <Progress trailColor={trailColor} percent={(finishedRiddles/allRiddles) * 100} showInfo={false} />
                    }
                    <Number size={size}>{percentage ? `${perc} %` : `${finishedRiddles} / ${allRiddles}`}</Number>
                </>
            }
        </div>
    )
}, false)

export {AdventureProgress}