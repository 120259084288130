import React, {useMemo, useState} from 'react'
import {withAdventures} from "@components/common/with-adventures";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Box} from "@packages/components/layout/box";
import {useTranslation} from "react-i18next";
import {RiddleComponent} from "@packages/components/riddle/riddle";
import {Map} from '@components/map'

import styles from "./adventure.module.less";
import {Page} from "@components/common/page";
import {useAdventureStatus} from '@hooks/useStatus';
import {Button, Drawer, Result, Tag} from "antd";
import {ArrowLeftOutlined, MehOutlined, RadarChartOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {RiddleSummary} from "@components/common/riddle-summary";
import {AdventureProgress} from "@components/common/adventure-progress";
import {isCoords, toMapRiddle} from "@packages/commons/src/domain";
import {NotFound} from "@packages/components/status/not-found";
import {Centered} from "@packages/components/layout/centered";
import {useLocalStorage} from "usehooks-ts";
import {ToggleButton} from "@packages/components/common/toggle-button";
import {ReactComponent as MapIcon} from "@packages/components/icons/map.svg";
import {ReactComponent as ListIcon} from "@packages/components/icons/list.svg";
import {ReactComponent as BackIcon} from "@packages/components/icons/back.svg";
import {SuccessButton} from "@packages/components/common/success-button";
import clsx from "clsx";
import {motion, AnimatePresence} from "framer-motion"
import {useSwipeable} from "react-swipeable";
import {SOUND, play} from "@packages/commons/src/sound";


type ViewMode = 'map' | 'list'

const Adventure = withAdventures(({adventures}) => {
    const {t} = useTranslation();
    const {adventureId, orgId} = useParams()
    const adventure = adventures.find(a => a.id === adventureId)
    const navigate = useNavigate();
    const status = useAdventureStatus()
    const [mode, setMode] = useLocalStorage<ViewMode>(`view-mode-${orgId}-${adventureId}`, 'map')
    const [selectedRiddleId, setSelectedRiddleId] = useState<string | null>(null)
    const riddles = adventure?.riddles.sort((a, b) => a.name.localeCompare(b.name)) || []
    const [isSwiping, setIsSwiping] = useState(false)

    const handlers = useSwipeable({
        onSwipedDown: (eventData) => {
            setSelectedRiddleId(null)
        },
        onSwiped: eventData => {
            setIsSwiping(false)
        },
        onSwipeStart: eventData => {
            setIsSwiping(true)
        },
        ...{
            delta: 10,                             // min distance(px) before a swipe starts. *See Notes*
            preventScrollOnSwipe: true,           // prevents scroll during swipe (*See Details*)
            trackTouch: true,                      // track touch input
            trackMouse: false,                     // track mouse input
            rotationAngle: 0,                      // set a rotation angle
            swipeDuration: Infinity,
            touchEventOptions: {passive: true},  // options for touch listeners (*See Details*)
        },
    });

    const selectedRiddle = useMemo(() => {
        return riddles.find(r => r.id === selectedRiddleId)
    }, [riddles, selectedRiddleId])

    const Header = (<div className={clsx(styles.header, mode)}>
        <div>
            <Link onClick={() => {
                play(SOUND.TAP)
            }} to={`/${orgId}`}>
                <BackIcon className={clsx(styles.backIcon, mode)} />
            </Link>
            {adventure?.name}
        </div>
        <AdventureProgress percentage showBar={false} mode={mode === 'map' ? "inverted" : "default"}/>
    </div>)

    if (!adventure) {
        return (
            <NotFound headerText={t('adventure_not_found')} link={`/${orgId}`}/>
        )
    }

    if (!riddles.length) {
        return (
            <Centered>
                <Result
                    icon={<MehOutlined/>}
                    title={t('empty_riddles')}
                    extra={<Link to={`/${orgId}`}>
                        <Button type="primary">{t('no_match.back')}</Button>
                    </Link>}
                />
            </Centered>
        )
    }

    const mapRiddles = riddles
        .filter(riddle => isCoords(riddle))
        .map(riddle => ({...toMapRiddle(riddle), finished: !!status.riddles[riddle.id]}))

    const footer = selectedRiddleId ?
        <SuccessButton block onClick={() => {
            play(SOUND.TAP);
            navigate(`riddle/${selectedRiddleId}`)
        }}>
            {t('adventure.show_riddle')}
        </SuccessButton>
        : mapRiddles.length > 0 ?
            <div className={styles.footerButtons}>
                <ToggleButton
                    block
                    checked={mode === 'map'}
                    onClick={() => {
                        play(SOUND.TAP)
                        setMode('map')
                        setSelectedRiddleId(null)
                    }}
                    icon={(
                        <MapIcon className={styles.icon} width={24} height={24}/>)}>{t('adventure.map')}</ToggleButton>
                <ToggleButton
                    block
                    checked={mode === 'list'}
                    onClick={() => {
                        play(SOUND.TAP)
                        setMode('list')
                        setSelectedRiddleId(null)
                    }}
                    icon={(<ListIcon className={styles.icon} width={24}
                                     height={24}/>)}>{t('adventure.list')}</ToggleButton>
            </div> : null

    return (
        <Page className={clsx(styles.root)}
              headerSize="small"
              header={Header}
              footer={footer}
              mode={mode === "map" ? "default" : "inverted"}
        >
            {
                (mode === 'list' || mapRiddles.length == 0) &&
                <div className={styles.riddleList}>
                    {
                        riddles.map((riddle) => {
                            return (
                                <motion.div key={riddle.id} whileTap={{scale: 0.8}}>
                                    <Box onClick={() => {
                                        navigate(`riddle/${riddle.id}`)
                                        play(SOUND.TAP)
                                    }}>
                                        <RiddleComponent status={status.riddles[riddle.id]} mode="app" riddle={riddle}/>
                                    </Box>
                                </motion.div>
                            )
                        })
                    }
                </div>
            }
            {
                mode === 'map' && mapRiddles.length > 0 &&
                <div className={styles.mapWrapper}>
                    <Map onClick={(id) => {
                        play(SOUND.TAP)
                        setSelectedRiddleId(id)
                    }} selectedRiddleId={selectedRiddleId}
                         riddles={mapRiddles}
                         withPath={adventure.withPath}
                    />
                </div>
            }
            <AnimatePresence>
                {
                    selectedRiddle && (<motion.div
                        {...handlers}
                        key={selectedRiddleId}
                        layout
                        initial={{opacity: 0.7, height: 0}}
                        animate={{opacity: 1, height: '125px'}}
                        exit={{opacity: 0.7, height: 0}}
                        transition={{duration: 0.5}}
                        className={styles.drawer}>
                        <svg width="73" height="4" viewBox="0 0 73 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.232025" width="72.536" height="3.21346" rx="1.60673" fill={isSwiping ? "darkgray": "#DCEBEA"}/>
                        </svg>
                        <div className={styles.drawerBox}>
                            <RiddleSummary riddle={selectedRiddle}/>
                        </div>
                    </motion.div>)
                }
            </AnimatePresence>
        </Page>
    )
})

export {Adventure}