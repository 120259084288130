import {
    Adventure,
    AdventureStatus,
    Nullable,
    Riddle,
    RiddleState,
    RiddleStatus,
    RiddleType,
    Status
} from "@packages/types/domain";
import {Coords} from "google-map-react";

const EMPTY_DESCRIPTION = '<p><br></p>'

export const getActiveRiddles = (adventure: Adventure | undefined) => adventure ? adventure.riddles?.filter(r => r.state === 'published') || [] : []

export const getAllRiddlesLength = (adventure: Adventure) => adventure?.riddles?.length || 0
export const getActiveRiddlesLength = (adventure: Adventure) => getActiveRiddles(adventure).length || 0

export const isCoords = (obj: any) => {
    return obj.lng && obj.lat
}

export const toMapRiddle = (riddles: Riddle) => {
    return riddles as (Riddle & Coords)
}

type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>

export const isDescriptionValid = (desc: Nullable<string> | undefined) => desc && desc !== EMPTY_DESCRIPTION

export const isRiddlePublishable = (riddle: AtLeast<Riddle, "type">): boolean => {
    switch (riddle.type) {
        case "text_code":
            return !!(riddle.code && isDescriptionValid(riddle.description));
        case "place":
            return !!(riddle.lat && riddle.lng && isDescriptionValid(riddle.description));
        case "qr_code":
        default:
            return !!(riddle.lat && riddle.lng && riddle.code && isDescriptionValid(riddle.description));
    }
    throw "Not supported"
}

export const getFinishedRiddles = (adventure: Adventure, riddlesStatus: AdventureStatus['riddles'] | undefined) =>
    riddlesStatus ? adventure.riddles.filter(riddle => riddlesStatus[riddle.id]).length || 0 : 0

export const findRiddleByCode = (code: string, adventures: Adventure[]) => {
    const riddle = adventures.map(a => a.riddles).flat().find(r => r.code === code)
    const adventure = adventures.find(a => a.riddles.find(r => r.id === riddle?.id))
    return {
        riddle, adventure
    }
}

export const changeRiddleStatus = (riddles: Riddle[], riddle: Riddle, state: Riddle["state"]) => {
    return riddles.map((r) => r.id === riddle.id ? {
        ...riddle,
        state,
    } : r)
}

const QR_ERROR_MESSAGE = 'Wrong QR code'

export const getCodeFromUrl = (url: string) => {
    if (!url.startsWith("https://app.georiddle.com/") || !url.includes('code')) {
        throw QR_ERROR_MESSAGE
    }
    let code: string | null = null
    try {
        code = url.split('/code/')[1]
    } catch (e) {
        throw QR_ERROR_MESSAGE
    }
    if (!code) {
        throw 'Wrong QR code'
    }
    return code;
}

export const getPrizeId = (orgId: string, code: string) => `${orgId}-${code}`

export const modifyOrgCodes = (riddleType: RiddleType, code: string | null) => riddleType === "qr_code" && code