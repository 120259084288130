import React from 'react'

import styles from './riddle.module.less'
import clsx from "clsx";
import {Mode, Riddle, RiddleState, RiddleStatus} from "@packages/types/domain";
import {useTranslation} from "react-i18next";
import {DifficultyComponent} from "@packages/components/difficulty";
import {Title, Label, Number} from "@packages/components/typography";
import {Popover, Tag} from "antd";
import {ReactComponent as Checked} from "@packages/components/icons/checked.svg";
import {RiddleTypeComponent} from "@packages/components/riddle/riddle-type";
import {Distance} from "@packages/components/geo/distance";
import { CheckCircleTwoTone, CloseCircleTwoTone, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import {isCoords, isDescriptionValid, isRiddlePublishable} from "@packages/commons/src/domain";
import {useBreakpoint} from "@packages/components/hooks/useBreakpoint";

type RiddleComponentProps = {
    riddle: Riddle
    status?: RiddleStatus
    className?: string
    mode?: Mode
}

const stateToColorMap: {[key in RiddleState]: string | undefined} = {
    'draft': undefined,
    'published': 'orange',
    'inactive': undefined,
}

const RiddleStateComponent = ({state}: {state: RiddleState}) => {
    const {t} = useTranslation("common")

    return (
        <>
        <Tag className={styles.tag} color={stateToColorMap[state]}>
            {t(`components.riddle_state.${state}`)}
            {
                state === 'published' ? <EyeOutlined /> : <EyeInvisibleOutlined />
            }
        </Tag>
        </>
    )
}

const IsValidIcon = ({valid}: {valid: boolean}) => {
    if (valid) {
        return <CheckCircleTwoTone twoToneColor="#52c41a" />
    }

    return <CloseCircleTwoTone twoToneColor="#D90A03FF" />
}

const RiddleStateInfo = ({riddle, riddlePublishable}: {riddle: Riddle, riddlePublishable: boolean}) => {
    const {t} = useTranslation("common")
    return (
        <div>
            <RiddleStateComponent state={riddle.state} />
            {
                !riddlePublishable &&
                <Popover placement="top" content={(<div className={styles.missingDataInfo}>
                    {
                        riddle.type === 'qr_code' &&
                        <div>
                            <span>{t('location')}:</span>
                            <IsValidIcon valid={!!(riddle.lat && riddle.lng)} />
                        </div>
                    }
                    {
                        riddle.type != 'place' &&
                        <div>
                            <span>{t('code')}:</span>
                            <IsValidIcon valid={!!(riddle.code)} />
                        </div>
                    }
                    <div>
                        <span>{t('description')}:</span>
                        <IsValidIcon valid={!!(isDescriptionValid(riddle.description))} />
                    </div>
                </div>)} trigger="click">
                    <Tag className={styles.missingData} color="#D90A03FF">{t('components.riddle.missing_data')}</Tag>
                </Popover>
            }
        </div>
    )
}

const RiddleComponent = ({riddle, status, className, mode = 'app'}: RiddleComponentProps) => {
    const {t} = useTranslation("common")
    const riddlePublishable = isRiddlePublishable(riddle)
    const {isSm} = useBreakpoint("sm")

    return (
        <div className={clsx(styles.root, className, mode)}>
            <header>
                <div className={styles.centeredHeader}>
                    <Title>
                        {riddle.name}
                    </Title>
                </div>
                {
                    mode === 'app' &&
                    <>
                        {
                            status?.finishDate &&
                            <Checked className={styles.checked} />
                        }
                        {
                            riddle.state !== 'published' &&
                            <RiddleStateInfo riddle={riddle} riddlePublishable={riddlePublishable} />
                        }
                    </>
                }
            </header>
            <main>
                <section>
                    {
                        mode === 'user' &&
                        <Label size="large">{t('difficulty')}</Label>
                    }
                    <DifficultyComponent difficulty={riddle.difficulty} />
                </section>
                {
                    mode === 'app' &&
                    (
                        <div className={styles.distance}>
                            {   // @ts-ignore
                                isCoords(riddle) && <Distance {...riddle} />
                            }
                        </div>
                    )
                }
                <section>
                    {
                        mode === 'user' &&
                        <Label size="large">{t('type')}</Label>
                    }
                    <RiddleTypeComponent withLabel={isSm || mode === 'app'} type={riddle.type} />
                </section>
                {
                    (mode === 'user') &&
                    <section>
                        <Label size="large">{t('status')}</Label>
                        <RiddleStateInfo riddle={riddle} riddlePublishable={riddlePublishable} />
                    </section>
                }
            </main>
        </div>
    )
}

export {RiddleComponent}
