import {useQuery} from "react-query";
import {getMultiple, setSingle} from "@packages/commons/src/services/firebase";
import {Adventure, Difficulty, Prize} from "@packages/types/domain";
import {useParams, useSearchParams} from "react-router-dom";
import {getActiveRiddles, getPrizeId} from "@packages/commons/src/domain";
import {useIsPreview} from "@hooks/useIsPreview";
import { addMinutes } from "date-fns";
import {makeNumberId} from "@packages/commons/src/object";

const compareDifficulty = (a: Difficulty, b: Difficulty) => {
    return a == b ? 0 : a === 'easy' ? -1 : b === 'easy' ? 1 : a === 'normal' ? -1 : 1
}

export const useAdventures = () => {
    const { orgId } = useParams();
    const isPreview = useIsPreview()

    return useQuery('adventures', async() => {
        const res = await getMultiple<Adventure>(`organizations/${orgId}/adventures`)
        return res.map(adventure => {
            const riddles = isPreview ? adventure.riddles : getActiveRiddles(adventure)
            return {...adventure, riddles }
        }).sort((a, b) => {
            const stringCompare = a.name.localeCompare(b.name)
            return stringCompare === 0 ? compareDifficulty(a.difficulty, b.difficulty) : stringCompare
        })
    }, { enabled: !!orgId })
}

export const addPrize = async (orgId: string, adventureId: string) => {
    const code = makeNumberId(5)
    const id = getPrizeId(orgId, code)
    // @ts-ignore
    const userId = window.gaGlobal.vid

    //TODO - check previous
    const prize: Prize = {
        id,
        code,
        expiresAt: addMinutes(new Date(), 20),
        orgId,
        adventureId,
        userId,
    }
    await setSingle<Prize>(`prizes/${id}`, prize)
    return prize
}