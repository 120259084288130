import React, {useRef} from "react";

import styles from './map.module.less'
import useElementSize from "@packages/components/hooks/useElementSize";
import {MapRiddle} from "@packages/components/geo/riddle-marker";
import {OnlineMap} from "@components/online-map";
import {OfflineMap} from "@components/offline-map";

export type MapProps = {
    riddles: MapRiddle[]
    selectedRiddleId: string | null
    onClick?: (id: string) => void
    withPath?: boolean,
    centerWithLocation?: boolean
}

export const Map = ({riddles, selectedRiddleId, onClick, centerWithLocation, withPath}: MapProps) => {
    const containerRef = useRef<HTMLDivElement>(null!);
    const {width, height} = useElementSize(containerRef)
    const isOffline = false

    return (
        <div className={styles.root} ref={containerRef}>
            {
                width && height && (
                    isOffline
                        ? <OfflineMap riddles={riddles} selectedRiddleId={selectedRiddleId} onClick={onClick} width={width}
                                      height={height}/>
                        : <OnlineMap centerWithLocation={centerWithLocation} onClick={onClick} riddles={riddles} selectedRiddleId={selectedRiddleId} width={width}
                                     height={height} withPath={withPath} />
                )
            }
        </div>
    );
};





