import React, {useCallback, useEffect, useMemo, useState} from "react";
import GoogleMap from "@packages/components/geo/google-map";
import {locationRef} from "@packages/commons/src/geo/track";
import {calcDistance, centerPoints, getBounds, getCenter} from "@packages/commons/src/geo";
import {MapRiddle, RiddleMarker} from "@packages/components/geo/riddle-marker";
import {MapProps} from "@components/map";
import GoogleMapReact, {Coords} from 'google-map-react'

export type OnlineMapProps = MapProps & {
    width: number,
    height: number,
}

const createRiddles = (riddles: MapRiddle[], onClick: ((id: string) => void) | undefined, selectedRiddleId: string | null, map: google.maps.Map | null) => {
    return riddles.map((point) => {
        const selected = point.id === selectedRiddleId
        const radius = (selected && point.radius) || 0
        return (<RiddleMarker map={map} radius={radius} key={point.id} lat={point.lat} lng={point.lng} riddle={point}
                              selected={selected} onClick={onClick}/>)
    })
}

export const OnlineMap = ({riddles, selectedRiddleId, onClick, height, width, withPath, centerWithLocation}: OnlineMapProps) => {
    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [defaultValues, setDefaultValues] = useState<{zoom: number, center: Coords} | null>(null)

    useEffect(() => {
        const {center} = getCenter(riddles, {width, height})
        const addCurrentLocation = locationRef.current && (calcDistance(center, locationRef.current) < 50)
        const locationPoints = addCurrentLocation ? [locationRef.current!] : []
        setDefaultValues(getCenter([...riddles, ...locationPoints], {width, height}))
    }, [map, centerWithLocation])

    const onMapLoaded = useCallback((mapInstance) => {
        setMap(mapInstance)
    }, [])

    const centerLocation = useCallback(() => {
        if (map === null || !locationRef.current) {
            return
        }
        centerPoints(map, [{lat: locationRef.current.lat, lng: locationRef.current.lng}], {width, height})
    }, [map])

    const riddleMarkers = useMemo(() => {
        return createRiddles(riddles || [], onClick, selectedRiddleId, map)
    }, [riddles, onClick, selectedRiddleId])


    useEffect(() => {
        if (map === null || !riddles || !withPath) {
            return
        }

        const paths = riddles.map((elem, index) => {
            const next = index + 1 < riddles.length ? riddles[index +1] : null
            return next ? [{lat: elem.lat, lng: elem.lng}, {lat: next.lat, lng: next.lng}] : null
        }).filter(e => !!e).map(coors => {
            return new google.maps.Polyline({
                path: coors,
                geodesic: true,
                strokeColor: "#128E8A",
                strokeOpacity: 1.0,
                strokeWeight: 2,
            });
        })

        paths.forEach(path => path.setMap(map))
        return () => {
            paths.forEach(path => path.setMap(null))
        }
    }, [map, riddles, withPath])

    return (
        <>
            {
                defaultValues &&
                (<GoogleMap defaultZoom={defaultValues.zoom} defaultCenter={defaultValues.center} onLoad={onMapLoaded} onLocationClick={centerLocation}>
                    {
                        !map ? null : riddleMarkers
                    }
                </GoogleMap>)
            }
        </>
    );
};