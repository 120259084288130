import {convert, GeoLocation} from "./index";

let watchId: number | null = null;

export type GeoResult = 'finished' | 'not-available' | 'permission-denied' | 'position-not-available' | 'timeout' | 'unknown-error'

const checkGeolocation = () => {
    if (!('geolocation' in navigator)) {
        return false
    }
    return true;
}

const checkError = (e: GeolocationPositionError, reject: (res: GeoResult) => void) => {
    if (e.code === 1) {
        reject('permission-denied' as GeoResult)
    } else if (e.code === 2) {
        reject('position-not-available' as GeoResult)
    } else if (e.code === 3) {
        reject('timeout' as GeoResult)
    } else {
        reject('unknown-error')
    }
}

export const locationRef: { current: null | GeoLocation } = {
    current: null,
}

export const trackLocation = () => {
    return new Promise<GeoResult>((resolve, reject) => {
        if (!checkGeolocation()) {
            reject('not-available')
        }
        if (locationRef.current) {
            resolve('finished')
        }
        navigator.geolocation.getCurrentPosition((position) => {
            {
                watchId = navigator.geolocation.watchPosition((position) => {
                    if (position) {
                        locationRef.current = convert(position)
                    }
                }, null, {enableHighAccuracy: true})
                locationRef.current = convert(position)
                resolve('finished')
            }
        }, (e) => {
            checkError(e, reject)
        }, {enableHighAccuracy: true})
    })
}

export const stopTracking = () => {
    watchId && navigator.geolocation.clearWatch(watchId)
    watchId = null
    locationRef.current = null
}